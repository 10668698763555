import react, { useEffect, useState } from "react";
import * as s from "./styles/globalStyles";
import "rsuite/styles/index.less";
import "rsuite/dist/rsuite.css";
import { useAppContext } from "./context/AppContext";
import { UnauthedScreen, AuthedScreen } from "./components/index.js";

function App() {
	const {
		bgImages,
		CacheBgsNew,
		currPage,
		authed,
		useContractConfig,
		contractsList,
		setContractsList,
	} = useAppContext();
	CacheBgsNew();

	const conf = useContractConfig({ refresh: 30 });
	let isMounted;

	useEffect(() => {
		isMounted = true;
		if (isMounted) {
			if (
				!!conf &&
				typeof conf === "object" &&
				Object.keys(conf)?.length > 0
			) {
				let shouldUpdate = false;
				Object.entries(conf).forEach(([x, y]) => {
					if (
						contractsList?.[x]?.toString().toLowerCase() !==
						y?.toString()?.toLowerCase()
					)
						shouldUpdate = true;
				});
				if (!shouldUpdate) {
					return;
				}
				console.log("updating now...", new Date());

				const newContractsList = { ...contractsList, ...conf };
				setContractsList(newContractsList);
			}
		}
		return () => (isMounted = false);
	}, [conf]);

	return (
		<s.Screen
			style={{
				backgroundImage: `url('config/images/${bgImages[currPage]}')`,
			}}
		>
			{(authed && <AuthedScreen />) || <UnauthedScreen />}
		</s.Screen>
	);
}

export default App;
