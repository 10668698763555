import styled, { css, keyframes } from "styled-components";

const opaque = keyframes`
		0% {
			opacity: 0;
		}
		50% {
			opacity: 0.5;
		}
		100% {
			opacity: 1;
		}
  `;

export const borderAlternate = (props) => keyframes`
		0% {
		  border: 0px ${props.aBorder || "FF0000"}00 solid;
		}
		100% {
		  border: 3px ${props.aBorder || "FF0000"}FF solid;
		}
  `;

export const mainBreakPoint = "840px";
export const mainColor = "#FF407CFF";
export const mainFont = "RoadRage";
export const opaqueBgStyles = {
	backgroundColor: "#00059E50",
	backgroundColor: "#050F3880",
	borderRadius: "20px",
	padding: "15px",
};

export const borderStyles = (props) => css`
		${borderAlternate(props)} 0.5s infinite alternate;",
  `;

// Used for wrapping a page component
export const Screen = styled.div`
	background-color: #fff;
	width: 100vw;
	display: flex;
	flex-direction: column;
	// background-image: url('config/images/dojo night.png');
	// background-image: ${({ image }) =>
		image ? `url(${image})` : `url(config/images/dojo night.png)`};
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 100vh;
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
	height: 8px;
	width: 8px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
	height: 16px;
	width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
	height: 24px;
	width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
	height: 32px;
	width: 32px;
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
	display: flex;
	flex: ${({ flex }) => (flex ? flex : 0)};
	flex-direction: ${({ fd }) => (fd ? fd : "column")};
	justify-content: ${({ jc }) => (jc ? jc : "flex-center")};
	align-items: ${({ ai }) => (ai ? ai : "flex-center")};
	width: 100%;
	justify-content: space-between;
	// @media (max-width: ${mainBreakPoint}) {
	// 	flex-direction: row;
	// 	// text-align: center;
	// 	// letter-spacing: 2px;
	// }
`;

export const BigContainer = styled.div`
  display: flex;
  // flex: ${({ flex }) => (flex ? flex : 0)};
  flex: 1;
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "center")};
  // justify-content: space-between;
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  // background-color: ${({ test }) => (test ? "pink" : "none")};
  // align-items: start;
  width: 100%;
  background-size: 100vw 100vh;
  background-position: cover;
  background-repeat: none;
  height: max(100%, 100vmax));
  height: 100vmax;
  // height: 2000px;
`;

export const CenterContainer = styled.div`
	background-color: silver;
	background-image: radial-gradient(
			circle at 100% 150%,
			silver 24%,
			white 24%,
			white 28%,
			silver 28%,
			silver 36%,
			white 36%,
			white 40%,
			transparent 40%,
			transparent
		),
		radial-gradient(
			circle at 0px 150%,
			silver 24%,
			white 24%,
			white 28%,
			silver 28%,
			silver 36%,
			white 36%,
			white 40%,
			transparent 40%,
			transparent
		),
		radial-gradient(
			circle at 50% 100%,
			white 10%,
			silver 10%,
			silver 23%,
			white 23%,
			white 30%,
			silver 30%,
			silver 43%,
			white 43%,
			white 50%,
			silver 50%,
			silver 63%,
			white 63%,
			white 71%,
			transparent 71%,
			transparent
		),
		radial-gradient(
			circle at 100% 50%,
			white 5%,
			silver 5%,
			silver 15%,
			white 15%,
			white 20%,
			silver 20%,
			silver 29%,
			white 29%,
			white 34%,
			silver 34%,
			silver 44%,
			white 44%,
			white 49%,
			transparent 49%,
			transparent
		),
		radial-gradient(
			circle at 0px 50%,
			white 5%,
			silver 5%,
			silver 15%,
			white 15%,
			white 20%,
			silver 20%,
			silver 29%,
			white 29%,
			white 34%,
			silver 34%,
			silver 44%,
			white 44%,
			white 49%,
			transparent 49%,
			transparent
		);
	background-size: 100px 50px;
	--darkreader-inline-bgcolor: #3c4143;
	--darkreader-inline-bgimage: radial-gradient(
			circle at 100% 150%,
			#3c4143 24%,
			#181a1b 24%,
			#181a1b 28%,
			#3c4143 28%,
			#3c4143 36%,
			#181a1b 36%,
			#181a1b 40%,
			rgba(0, 0, 0, 0) 40%,
			rgba(0, 0, 0, 0)
		),
		radial-gradient(
			circle at 0px 150%,
			#3c4143 24%,
			#181a1b 24%,
			#181a1b 28%,
			#3c4143 28%,
			#3c4143 36%,
			#181a1b 36%,
			#181a1b 40%,
			rgba(0, 0, 0, 0) 40%,
			rgba(0, 0, 0, 0)
		),
		radial-gradient(
			circle at 50% 100%,
			#181a1b 10%,
			#3c4143 10%,
			#3c4143 23%,
			#181a1b 23%,
			#181a1b 30%,
			#3c4143 30%,
			#3c4143 43%,
			#181a1b 43%,
			#181a1b 50%,
			#3c4143 50%,
			#3c4143 63%,
			#181a1b 63%,
			#181a1b 71%,
			rgba(0, 0, 0, 0) 71%,
			rgba(0, 0, 0, 0)
		),
		radial-gradient(
			circle at 100% 50%,
			#181a1b 5%,
			#3c4143 5%,
			#3c4143 15%,
			#181a1b 15%,
			#181a1b 20%,
			#3c4143 20%,
			#3c4143 29%,
			#181a1b 29%,
			#181a1b 34%,
			#3c4143 34%,
			#3c4143 44%,
			#181a1b 44%,
			#181a1b 49%,
			rgba(0, 0, 0, 0) 49%,
			rgba(0, 0, 0, 0)
		),
		radial-gradient(
			circle at 0px 50%,
			#181a1b 5%,
			#3c4143 5%,
			#3c4143 15%,
			#181a1b 15%,
			#181a1b 20%,
			#3c4143 20%,
			#3c4143 29%,
			#181a1b 29%,
			#181a1b 34%,
			#3c4143 34%,
			#3c4143 44%,
			#181a1b 44%,
			#181a1b 49%,
			rgba(0, 0, 0, 0) 49%,
			rgba(0, 0, 0, 0)
		);
`;

export const AmountContainer = styled.div`
	display: flex;
	flex: ${({ flex }) => (flex ? flex : 0)};
	flex-direction: center;
	justify-content: center;
	align-items: center;
	//border: 2px solid #A673EF;
	border-radius: 10px;
	//box-shadow: 0px 0px 3px 0px #A673EF;
`;

export const UnauthContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	// flex: ${({ flex }) => (flex ? flex : 0)};
	// flex-direction: center;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	height: 100vh;
`;

export const TextTitle = styled.p`
	font-family: ${mainFont};
	color: rgb(255, 168, 18);
	color: ${mainColor};
	font-size: clamp(2.5rem, 4vw, 4rem);
	font-weight: 1000;
	letter-spacing: 0.2rem;
	line-height: 1;
	text-align: center;
	margin-bottom: 2rem;
	display: inline-block;
	${css`
		animation: ${borderAlternate} 1s infinite alternate;
	`}
	@media (max-width: ${mainBreakPoint}) {
		// font-size: 25px;
		text-align: center;
		letter-spacing: 2px;
	}
`;

export const TextSub = styled.p`
	font-family: "${mainFont}";
	color: ${mainColor};
	font-size: 24px;
	font-weight: 1000;
	letter-spacing: 8px;
	line-height: 1.6;
	@media (max-width: ${mainBreakPoint}) {
		font-size: 20px;
		letter-spacing: 2px;
	}
`;

export const TextTotal = styled.p`
	font-family: "${mainFont}";
	color: ${mainColor};
	background-color: #4c0f52;
	font-size: 1rem;
	font-weight: 1000;
	letter-spacing: 2px;
	line-height: 1.6;
	@media (max-width: ${mainBreakPoint}) {
		font-size: 0.75rem;
		letter-spacing: 2px;
	}
`;

export const TextPrice = styled.p`
	font-family: "${mainFont}";
	color: white;
	font-size: 3rem;
	font-weight: bold;
	letter-spacing: 8px;
	line-height: 1.6;
	border: 2px solid midnightblue;
	width: 400px;
	background: #9dc5f0;
	text-align: center;
	@media (max-width: ${mainBreakPoint}) {
		font-size: 20px;
		letter-spacing: 2px;
		width: 200px;
	}
`;

export const TextSubTitle = styled.p`
	font-family: "${mainFont}";
	color: rgb(255, 168, 18);
	// font-size: 20px;
	font-size: clamp(1rem, 2vw, 3rem);
	font-weight: 600;
	// line-height: 1.6;
	text-align: center;
	@media (max-width: ${mainBreakPoint}) {
		font-size: 0.75rem;
		letter-spacing: 2px;
		text-align: center;
	}
`;

export const TextDescription = styled.p`
	color: rgb(255, 168, 18);
	font-size: 60px;
	line-height: 1.6;
`;

export const TEXTamount = styled.p`
	color: white;
	font-size: 1.8rem;
	line-height: 1.6;
	@media (max-width: ${mainBreakPoint}) {
		font-size: 20px;
		line-height: 0;
	}
`;

export const StyledClickable = styled.div`
	:active {
		opacity: 0.6;
	}
`;

export const StyledHR = styled.hr`
	border: 2px solid white;
	background-color: white;
	border-radius: 2px;
	width: 450px;
	@media (max-width: ${mainBreakPoint}) {
		width: 350px;
	}
`;

export const StyledLink = styled.a`
	color: rgb(255, 168, 18);
	color: #0f2fff;
	color: ${mainColor};
	font-size: 2rem;
	font-size: clamp(1rem, 2vw, 3rem);
	text-decoration: none;
	margin-right: 2rem;
	text-align: center;
	cursor: pointer;
	:hover {
		color: #ff8938;
	}
	@media (max-width: ${mainBreakPoint}) {
		margin-right: 0.75rem;
		margin-top: 1rem;
	}
`;

export const Icons = styled.img`
	width: 30px;
	height: 30px;
	border-radius: 50%;
	margin-right: 1.2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	:hover {
		transform: scale(1.5);
		transition: transform 0.5s;
	}
`;

export const HeaderDiv = styled.div`
	display: flex;
	align-items: center;
	margin-right: 3.5rem;
	@media (max-width: ${mainBreakPoint}) {
		margin-right: 0;
		margin-top: 10px;
		margin-bottom: 20px;
		flex-direction: column;
	}
`;

export const socialDiv = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	width: clamp(200px, 30vw, 400px);
	@media (max-width: ${mainBreakPoint}) {
		margin-right: 0;
		margin-top: 20px;
		flex-direction: row;
	}
`;

export const iconDiv = styled.div`
	transition: all 0.5s;
	:hover {
		transform: scale(1.5);
	}
`;

export const connectionIndicator = styled.div`
  font-size: 1rem;
  animation: fadein 1s alternate infinite;
  @keyframes fadein {
    0% {scale(1);}
    100%: {scale(2);}
  }  
`;

export const Headerlinks = styled.div`
	font-family: ${mainFont};
	display: flex;
	align-items: center;
	@media (max-width: ${mainBreakPoint}) {
		margin-top: 10px;
		margin-bottom: 10px;
	}
`;

export const SecContainer = styled.div`
	display: flex;
	flex: ${({ flex }) => (flex ? flex : 0)};
	flex-direction: ${({ fd }) => (fd ? fd : "column")};
	justify-content: center;
	align-items: center;
	// background-color: ${({ test }) => (test ? "pink" : "none")};
	background-image: ${({ image }) => (image ? `url(${image})` : "none")};
	width: 100%;
	// height: 100vh;
	// margin: 0 5rem;
	// height: auto;
`;

export const TextP = styled.p`
	font-family: "${mainFont}";
	color: rgb(255, 168, 18);
	color: #45b1e8;
	color: ${mainColor};
	background-color: #050f3880;
	border-radius: 20px;
	padding: 15px;
	text-align: center;
	font-size: clamp(1.4rem, 1.8vw, 2.2rem);
	letter-spacing: 0.05rem;
	line-height: 1.8rem;
	width: 85%;
`;

export const TextB = styled.p`
	font-family: "${mainFont}";
	color: ${mainColor};
	text-align: center;
	font-size: clamp(1.2rem, 2vw, 2.5rem);
	letter-spacing: 2px;
	line-height: 2rem;
`;

export const ExploreBTN = styled.button`
	font-family: "${mainFont}";
	padding: 10px;
	font-size: 1rem;
	border-radius: 40px;
	border: none;
	background: linear-gradient(
		86.57deg,
		#d8ae43 6.92%,
		rgba(255, 92, 0, 0.79) 100.73%
	);
	padding: 10px;
	letter-spacing: 2px;
	font-weight: bold;
	color: white;
	width: 270px;
	height: 50px;
	cursor: pointer;
	box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
	-webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
	-moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
	:active {
		box-shadow: none;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
	}
	:hover {
		color: black;
	}
	@media (max-width: ${mainBreakPoint}) {
		width: 200px;
		height: 50px;
		font-size: 0.75rem;
	}
`;

export const imgContainer = styled.div`
	display: flex;
	background: #171717;
	border-radius: 40px;
	width: max-content;
	margin-top: 5%;
	align-items: center;
	justify-content: center;
	@media (max-width: ${mainBreakPoint}) {
		width: 100%;
	}
`;

export const workContainer = styled.div`
	display: flex;
	margin-top: 5%;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	gap: 5%;
	@media (max-width: ${mainBreakPoint}) {
		flex-direction: column;
	}
`;

export const Box = styled.div`
	height: 120px;
	width: 250px;
	border-radius: 20px;
	background: #171717;
	padding: 10px;
	@media (max-width: ${mainBreakPoint}) {
		margin-top: 10px;
	}
	:hover {
		transform: scale(1.2);
	}
`;

export const innerbox = styled.div`
	flex-direction: row;
	display: flex;
	align-items: center;
	justify-content: center;
	align-content: center;
	padding: 10px;
`;

export const innerIcons = styled.img`
	width: 45px;
	height: 45px;
	border-radius: 50%;
	margin-right: 1.2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`;

export const TextPBOX = styled.p`
	text-align: left;
	width: 125px;
	font-size: 1rem;
`;

export const Textbox = styled.p`
	text-align: left;
	font-size: 0.75rem;
`;

export const CBOX = styled.div`
	width: 30%;
	@media (max-width: ${mainBreakPoint}) {
		width: 80%;
	}
`;

export const StyledButton = styled.button`
	font-family: "${mainFont}";
	padding: 10px;
	font-size: 1rem;
	border-radius: 6px;
	border: none;
	background-color: black;
	padding: 10px;
	letter-spacing: 2px;
	font-weight: bold;
	color: white;
	width: 270px;
	height: 50px;
	cursor: pointer;
	box-shadow: 0px 6px 0px -2px black;
	-webkit-box-shadow: 0px 6px 0px -2px black;
	-moz-box-shadow: 0px 6px 0px -2px black;
	:active {
		box-shadow: none;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
	}
	:hover {
		color: silver;
	}
	@media (max-width: ${mainBreakPoint}) {
		width: 200px;
		height: 50px;
		font-size: 0.75rem;
	}
`;

export const StyledRoundButton = styled.button`
	padding: 10px;
	border-radius: 10px;
	border: none;
	background-color: transparent;
	padding: 10px;
	font-weight: bold;
	font-size: 30px;
	color: white;
	width: 40px;
	height: 40px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	:active {
		box-shadow: none;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
	}
	:hover {
		color: silver;
	}
`;

export const CTNButton = styled.button`
	font-family: "${mainFont}";
	font-size: 1rem;
	border-radius: 0.5rem;
	border: none;
	background-color: black;
	background-color: #4c0f52;
	padding: 1rem;
	letter-spacing: 0.1rem;
	font-weight: bold;
	color: #e10f96;
	width: 270px;
	height: 50px;
	cursor: pointer;
	// box-shadow: 0px 6px 0px -2px black;
	// -webkit-box-shadow: 0px 6px 0px -2px black;
	// -moz-box-shadow: 0px 6px 0px -2px black;
	:active {
		box-shadow: none;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
	}
	:hover {
		color: silver;
	}
	@media (max-width: ${mainBreakPoint}) {
		width: 200px;
		height: 50px;
		font-size: 0.75rem;
	}
`;

export const Maxbtn = styled.button`
	font-family: "${mainFont}";
	font-size: 0.75rem;
	border-radius: 10px;
	background-color: black;
	border: 2px solid black;
	font-weight: bold;
	color: white;
	width: 80px;
	height: 30px;
	cursor: pointer;
	letter-spacing: 1px;
	:hover {
		color: black;
	}
	@media (max-width: ${mainBreakPoint}) {
		width: 200px;
		height: 50px;
		font-size: 0.75rem;
	}
`;

export const LogoDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	align-content: center;
	gap: 10%;
	width: 200px;
	// max-height: 100%
`;

export const ResponsiveWrapper = styled.div`
	display: flex;
	// flex: 1;
	// overflow: auto;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: auto;
	width: auto;
	max-width: 90%;
	// height: clamp(200px, auto, 1200px);
	height: max-content;
	border: 2px solid white;
	border-radius: 40px;
	background-color: #4ecefb55;
	// height: 50%;
	@media (min-width: ${mainBreakPoint}) {
		flex-direction: row;
		// justify-content
	}
`;

export const ResponsiveWrapperHeader = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	width: 100%;
	max-height: clamp(100px, 10vh, 200px);
	padding: 10px;
	// background-color : #4ecefb;
	@media (min-width: ${mainBreakPoint}) {
		flex-direction: row;
	}
	@media (max-width: ${mainBreakPoint}) {
		max-height: 300px;
	}
`;

export const StyledLogo = styled.img`
	display: flex;
	// max-height: 100%;
	// max-width: 70%;
	width: clamp(150px, 20vw, 70%);
	// height: 100%;
	@media (max-width: 566px) {
		// width: 150px;
	}
	transition: width 0.5s;
	transition: height 0.5s;
`;

export const StyledImg = styled.img`
	margin-right: 1rem;
	width: 80%;
	border-radius: 1rem;
	@media (min-width: 900px) {
		width: 450px;
	}
	@media (min-width: 1000px) {
		width: 450px;
	}
	transition: width 0.5s;
	@media (max-width: ${mainBreakPoint}) {
		width: 200px;
	}
`;

export const StyledImgMain = styled.img`
	margin-right: 1rem;
	margin-bottom: 1.5rem;
	// min-width: 20vw;
	// max-width: 100%;
	// height: 50%
	width: clamp(40%, 30vw, 60%);
	// max-height: 100%
	border-radius: 1rem;
	transition: all width 0.5s;
	animation: fadein 1s;
	@media (max-width: ${mainBreakPoint}) {
		// width: 80%;
	}
	@keyframes fadein {
		0% {
			opacity: 0;
		}
		100%: {
			opacity: 1;
		}
	}
`;

export const StyledImgSplash = styled.img`
	margin-right: 1rem;
	width: clamp(50%, 30vmax, 80%);
	border-radius: 100rem;
	transition: all width 1s;
	animation: fadein 1s alternate infinite;
	opacity: 0.75;
	@keyframes fadein {
		0% {
			opacity: 0;
		}
		100%: {
			opacity: 0.75;
		}
	}
`;

export const ResponsiveWrapperTraits = styled.div`
	display: flex;
	flex: 1;
	gap: 2rem;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	// margin: 0 auto;
	// width: auto;
	height: clamp(700px, 100%, 1000px);
	// width: 90%
	// max-width: 90%;
	// min-height: 100%;
	// border: 2px solid white;
	// border-radius: 40px;
	// background-color: #4ecefb55;
	// height: 50%;
	@media (max-width: 566px) {
		// height: 500px;
	}
`;

export const ResponsiveWrapperTrait = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: auto;
  width: auto;
  gap: clamp(20px, 5vw, 50px);
  // max-width: 90%;
  width: 90%;
  height: 100%;
  }
`;

export const TextO = styled.p`
	font-family: "${mainFont}";
	color: ${mainColor};
	text-align: left;
	font-size: clamp(1rem, 3vmax, 3rem);
	letter-spacing: 0.05rem;
	line-height: 1.8rem;
	width: 85%;
	animation: fadein 1s;
	@keyframes fadein {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
`;

export const InputO = styled.input`
	font-family: "${mainFont}";
	color: ${mainColor};
	text-align: center;
	background: #0000;
	font-size: clamp(1rem, 3vmax, 3rem);
	width: clamp(80px, 5vw, 150px);
	border: 0;
	border-bottom: 2px solid ${({ borderColor }) => borderColor || "red"};
	:focus {
		// border:0;
		outline: none;
	}
`;

export const MintBtn = styled.button`
	font-family: "${mainFont}";
	border-radius: 1rem;
	width: clamp(180px, 10vw, 300px);
	height: clamp(50px, 100%, 150px);
	display: flex;
	align-items: center;
	justify-content: center;
	border: 2px solid green;
	background: #050f3880;
	padding: 10px;
	font-size: clamp(0.7rem, 3vmax, 1.5rem);
	padding: 10px;
	letter-spacing: 2px;
	color: green;
	width: 100%;
	// height: 50px;
	cursor: pointer;
	:active {
		box-shadow: none;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
	}
	:hover {
		color: silver;
		// font-size: clamp(1rem, 4vmax, 1.8rem);
	}
	:disabled {
		border: 2px solid gray;
		color: gray;
		// background: gray;
	}
`;

export const StyledVid = styled.video`
	margin-right: 1rem;
	width: 70%;
	border-radius: 1rem;
	@media (min-width: 900px) {
		width: 450px;
		width: 70%;
	}
	@media (min-width: 1000px) {
		width: 450px;
		width: 70%;
	}
	transition: width 0.5s;
	@media (max-width: ${mainBreakPoint}) {
		width: 200px;
		width: 70%;
	}
`;

export const Styledroad = styled.img`
	width: 100%;
	border-radius: 5px;
	transition: width 0.5s;
`;

export const StyledImgSmall = styled.img`
	width: 220px;
	height: 220px;
	border-radius: 5px;
	@media (min-width: 900px) {
		width: 220px;
		height: 220px;
	}
	@media (min-width: 1000px) {
		width: 220px;
		height: 220px;
	}
	transition: width 0.5s;
	@media (max-width: ${mainBreakPoint}) {
		width: 200px;
	}
`;

export const StyledLinkBottom = styled.a`
	// color: var(--secondary);
	color: #5e99ff;
	// background-color: #5e99ff;
	text-decoration: none;
	font-family: ${mainFont};
	font-size: 4rem;
`;

export const WalletBox = styled.div`
	text-decoration: none;
	border-radius: 1rem;
	background-color: transparent;
	// padding: 10px;
	// font-weight: bold;
	font-size: 1rem;
	cursor: pointer;
	width: clamp(180px, 10vw, 300px);
	height: clamp(50px, 5vw, 60px);
	display: flex;
	// gap: 5%;
	align-items: center;
	justify-content: center;
	border: 2px solid #ff0800ff;
	background: #050f3880;
	// box-shadow: 0px 4px 0px -2px white;
	// -webkit-box-shadow: 0px 4px 0px -2px white;
	// -moz-box-shadow: 0px 4px 0px -2px white;
	@media (max-width: ${mainBreakPoint}) {
		margin-top: 20px;
		order: -1;
	}
`;

export const TextTitleAnimated = styled.p`
	font-family: ${mainFont};
	color: ${mainColor};
	width: auto;
	font-size: clamp(2.5rem, 4vw, 4rem);
	font-weight: 1000;
	overflow: hidden;
	white-space: nowrap;
	letter-spacing: 0.2rem;
	line-height: 1;
	// border-right: 0.15em solid orange;
	margin: 0 auto;
	text-align: center;
	margin-bottom: 2rem;
	// display: inline-block;
	// opacity: 0;
	// visibility: hidden;
	animation: typing 2s steps(40, end)
			${({ aDelay }) => (aDelay ? aDelay : "0")}s,
		opaque ${({ aDelay }) => (aDelay ? aDelay * 1.01 : "0")}s ease normal
			forwards;

	@keyframes typing {
		from {
			width: 0;
		}
		to {
			width: 100%;
		}
	}

	@keyframes opaque {
		0% {
			opacity: 0;
		}
		99.99% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
`;

export const InputOAnimated = styled.input`
	font-family: "${mainFont}";
	color: ${mainColor};
	text-align: center;
	background: #0000;
	font-size: clamp(1rem, 3vmax, 3rem);
	width: clamp(300px, 25vw, 500px);
	border: 0;
	border-bottom: 2px solid red;
	:focus {
		// border:0;
		outline: none;
	}
	animation: opaque ${({ aDelay }) => (aDelay ? aDelay : "0")}s ease normal
		forwards;

	@keyframes opaque {
		0% {
			opacity: 0;
		}
		99.9% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
`;

// .line__1,
// .line__2 {
//   white-space: nowrap; /* keep text in one line */
//   overflow: hidden; /* hide text behind the cursor */
//   margin: 0.5rem auto;
//   font-family: "Major Mono Display", monospace;
//   font-size: 2rem;
//   font-weight: bold;
// }

// .line__1 {
//   border-right: 2px solid rgba(17, 17, 17, 0.9);

//   /* define animation types for text + cursor */
//   animation: animated-text__1 6s steps(30, end) 1s 1 normal both,
//     animated-cursor__1 900ms steps(30, end) 8;
// }

// .line__2 {
//   border-right: 2px solid rgba(17, 17, 17, 0.9);

//   /* define animation types for text + cursor */
//   animation: animated-text__2 5s steps(30, end) 1s 1 normal both,
//     animated-cursor__2 900ms steps(30, end) infinite;
//   -webkit-animation-delay: 7s;
//   animation-delay: 7s;
// }

// @keyframes animated-text__1 {
//   from {
//     width: 0;
//   }
//   to {
//     width: 45rem;
//   }
// }

// @keyframes animated-text__2 {
//   from {
//     width: 0;
//   }
//   to {
//     width: 29rem;
//   }
// }
