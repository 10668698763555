import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import store from "./redux/store";
import { Provider } from "react-redux";
import "./styles/reset.css";
import GlobalStyle from "./fonts";
import { AppProvider } from "./context/AppContext";

import { DAppProvider, Mainnet } from "@usedapp/core";
import { ConfigProvider } from "@usedapp/core/dist/esm/src/providers";
import { Arbi, ETHMainnet, Pulse } from "./context/chains";

const customChain = Pulse;

const config = {
	networks: [customChain],
	readOnlyChainId: customChain.chainId,
	readOnlyUrls: {
		[customChain.chainId]: customChain.rpcUrl,
	},
};

ReactDOM.render(
	<DAppProvider config={config}>
		<ConfigProvider config={config}>
			<Provider store={store}>
				<GlobalStyle />
				<AppProvider>
					<App />
				</AppProvider>
			</Provider>
		</ConfigProvider>
	</DAppProvider>,
	document.getElementById("root")
);
