import * as s from "../styles/globalStyles";

import { BigNumber, utils } from "ethers";
import { useAppContext } from "../context/AppContext";
import { useState } from "react";
import { useConfig, useEtherBalance } from "@usedapp/core";

export const MintDataView = () => {
	const web3Config = useConfig();
	const nativeCurrencySymbol =
		web3Config.networks[0].nativeCurrency.symbol || "ETH";
	const {
		config,
		useContractMethods,
		account,
		connectionStatus,
		useContractMutation,
		getFormattedETH,
		contractsList,
	} = useAppContext();
	const [amount, setAmount] = useState("");
	const [pword, setPword] = useState("");
	const { NFT: NFTContractAddress, GEN: GenContractAddress } = contractsList;
	// const meta = (typeof pword === 'bigint') && BigNumber.from(BigInt(utils.solidityKeccak256(['uint256'], [pword]), 16))

	const mutationObject = useContractMutation(NFTContractAddress, "mint");

	const {
		state: { status: mintStatus },
		send: mintSend,
		events: mintEvents,
	} = mutationObject;

	const calls = [
		{
			contractAddress: NFTContractAddress,
			method: "balanceOf",
			args: [account],
		},
		{
			contractAddress: NFTContractAddress,
			method: "totalSupply",
			args: [],
		},
		{ contractAddress: NFTContractAddress, method: "maxSupply", args: [] },
		{ contractAddress: NFTContractAddress, method: "cost", args: [] },
		{ contractAddress: NFTContractAddress, method: "WLcost", args: [] },
		{
			contractAddress: GenContractAddress,
			method: "verifyCh",
			args: [BigNumber.from(pword || BigInt(0))],
		},
	];

	const values =
		NFTContractAddress &&
		GenContractAddress &&
		useContractMethods(calls, { keepError: true, dec: 0 });
	// console.log(values);
	const {
		balanceOf,
		totalSupply,
		maxSupply,
		cost: rawCost,
		WLcost: rawWLcost,
		verifyCh,
	} = values;
	const cost =
		typeof rawCost === "number" && utils.formatUnits(BigInt(rawCost), 18);
	const WLcost =
		typeof rawWLcost === "number" &&
		utils.formatUnits(BigInt(rawWLcost), 18);
	const realCost = (verifyCh && WLcost) || cost;
	const saleColor = verifyCh ? "green" : "red";
	const handleChange = (e) => {
		const val = e.target.value;
		const strVal = parseInt(val)?.toString();
		if (val === "" || val === "0") {
			setAmount(0);
			return;
		}
		(strVal === val || strVal === val.substring(1)) &&
			setAmount(
				Math.max(0, Math.min(Number(val), maxSupply - totalSupply))
			);
	};

	const handlePassChange = (e) => {
		const val = e.target.value;
		let newVal;
		try {
			newVal = BigInt(val);
		} catch (e) {
			1;
		}
		newVal && newVal !== pword && setPword(newVal);
		if (val === "" || val === "0") setPword(BigInt(0));
	};

	const mintCost = utils.parseEther((amount * realCost).toFixed(6));
	const ethBalance = useEtherBalance(account);
	const notConnected = connectionStatus() < 1 || !account;
	const insufficientBalance =
		BigInt(mintCost || 0) >= BigInt(ethBalance || 0);
	const noneSelected = amount && amount === 0;
	const txPending =
		mintStatus === "Pending signature" || mintStatus === "Mining";
	const sigPending = mintStatus === "PendingSignature";
	const mintSuccessful = mintStatus === "Success";
	const txError = mintStatus === "Exception";
	const showTooltip = () => mintDisabled() || mintSuccessful || txError;

	const mintDisabled = () => {
		return (
			notConnected ||
			insufficientBalance ||
			noneSelected ||
			txPending ||
			sigPending
		);
	};

	// console.log(getFormattedETH(mintCost), getFormattedETH(ethBalance))
	const handleClick = () => {
		if (connectionStatus() !== 1) return;
		try {
			// console.log(account, amount, pword || 0, { value: mintCost });
			mintSend(account, amount, pword || 0, { value: mintCost });
		} catch (e) {
			console.log(mintStatus);
		}
	};

	return (
		<s.ResponsiveWrapperTraits>
			<s.ResponsiveWrapperTrait>
				<s.TextO>Your Squad Size</s.TextO>
				<s.TextO key={balanceOf}>{balanceOf}</s.TextO>
			</s.ResponsiveWrapperTrait>
			<s.ResponsiveWrapperTrait>
				<s.TextO>Mycelians Reactivated</s.TextO>
				<s.TextO key={totalSupply}>{totalSupply}</s.TextO>
			</s.ResponsiveWrapperTrait>
			<s.ResponsiveWrapperTrait>
				<s.TextO>Mycelians Left</s.TextO>
				<s.TextO key={maxSupply}>{maxSupply - totalSupply}</s.TextO>
			</s.ResponsiveWrapperTrait>
			<s.ResponsiveWrapperTrait>
				<s.TextO style={{ color: saleColor }}>
					Reactivation cost
				</s.TextO>
				<s.TextO key={realCost} style={{ color: saleColor }}>
					{Number(realCost).toFixed(2)} {nativeCurrencySymbol}
				</s.TextO>
			</s.ResponsiveWrapperTrait>
			<s.ResponsiveWrapperTrait>
				<s.TextO>Mycelians to Reactivate:</s.TextO>
				<s.TextO
					style={{ display: "flex", maxWidth: "100%", width: "100%" }}
				>
					<s.InputO
						value={amount}
						onChange={(e) => handleChange(e)}
						disabled={mintDisabled()}
					/>
				</s.TextO>
			</s.ResponsiveWrapperTrait>
			<s.ResponsiveWrapperTrait>
				<s.TextO style={{ color: saleColor }}>
					MELT Cypher (optional):
				</s.TextO>
				<s.TextO
					style={{ display: "flex", maxWidth: "100%", width: "100%" }}
				>
					<s.InputO
						borderColor={saleColor}
						value={pword.toString()}
						onChange={(e) => handlePassChange(e)}
						style={{ color: saleColor }}
						disabled={mintDisabled()}
					/>
				</s.TextO>
			</s.ResponsiveWrapperTrait>
			<s.ResponsiveWrapperTrait style={{ marginTop: "2rem" }}>
				<s.MintBtn onClick={handleClick} disabled={mintDisabled()}>
					Reactivate {amount} Mycelians Now{" "}
					{`(${(amount * realCost).toFixed(
						5
					)} ${nativeCurrencySymbol})`}
				</s.MintBtn>
			</s.ResponsiveWrapperTrait>
			{showTooltip() && (
				<s.ResponsiveWrapperTrait>
					<s.TextO
						style={{
							fontSize: "clamp(1rem, 2vw, 1.5rem)",
							textAlign: "center",
							color: mintSuccessful
								? "green"
								: txPending || sigPending
								? "orange"
								: "red",
						}}
					>
						{(notConnected &&
							"Unable to mint: not connected. Please connect your wallet first.") ||
							(insufficientBalance &&
								`Unable to mint: insufficient balance (${Number(
									getFormattedETH(ethBalance)
								).toFixed(5)} ${nativeCurrencySymbol})`) ||
							(noneSelected &&
								"Unable to mint: no Mycelians chosen") ||
							(txError &&
								"Unable to mint: an error has occurred. Please try again.") ||
							(txPending &&
								"Reactivation in progress, please wait...") ||
							(sigPending &&
								"Reactivation about to start, please confirm the transaction...") ||
							(mintSuccessful &&
								"Reactivation successul. Good luck Commander.")}
					</s.TextO>
				</s.ResponsiveWrapperTrait>
			)}
		</s.ResponsiveWrapperTraits>
	);
};
