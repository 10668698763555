import { useAppContext } from "../context/AppContext";
import * as s from "../styles/globalStyles";
import { TokenView } from "./index";

export const HistorySection = () => {
	return (
		<s.SecContainer id="history">
			<s.TextTitle style={s.opaqueBgStyles}>
				A World in ruins that once was beautiful.
				<br />
				Our fate now lies in your hands commander.
			</s.TextTitle>
			<s.SpacerLarge />
			<TokenView />
		</s.SecContainer>
	);
};
