import { useAppContext } from "../context/AppContext";
import * as s from "../styles/globalStyles";
import { Badge } from "rsuite";
import { GoDotFill } from "react-icons/go";

const ConnectButton = () => {
	const { account, handleConnection, connectionStatus } = useAppContext();

	const conStatus = connectionStatus();
	const buttonColor = `${
		conStatus == 1 ? "#32F714FF" : conStatus == 0 ? "orange" : "red"
	}`;
	const wbStyle = { borderColor: buttonColor };
	const getDisplayAddress = () => {
		return conStatus == 1
			? account.substring(0, 6) + "..." + account.substring(38, 42)
			: conStatus == 0
			? "Change Network"
			: "Connect Wallet";
	};

	return (
		<s.WalletBox style={wbStyle} onClick={() => handleConnection()}>
			<s.connectionIndicator>
				<GoDotFill size={"1.2rem"} color={buttonColor} />
			</s.connectionIndicator>
			<s.TextSubTitle
				style={{
					fontSize: "clamp(1.2rem, 2vw, 1.8rem)",
					color: "white",
				}}
			>
				{getDisplayAddress()}
			</s.TextSubTitle>
		</s.WalletBox>
	);
};

export const HeaderContainer = () => {
	const { setCurrPage } = useAppContext();

	// const {blockchain, DOT, walletAddress, config, setCurrPage} = useAppContext();
	// const wbStyle = walletAddress.includes('0x') && {borderColor: '#32F714FF'} || {}
	return (
		<s.ResponsiveWrapperHeader style={{ marginBottom: "3rem" }}>
			<s.LogoDiv>
				<s.StyledLogo
					alt={"logo"}
					src={"/config/images/logo.jpg"}
					style={{ borderRadius: "50%", opacity: "0.8" }}
				/>
				{/* 
          <a href="#" target={"_blank"}>
            <s.StyledLogo alt={"logo"} src={"/config/images/logo-main.png"} />
          </a> */}
			</s.LogoDiv>

			<s.Headerlinks>
				<s.StyledLink onClick={() => setCurrPage("Story")}>
					The Mycelians Have Arisen
				</s.StyledLink>

				<s.StyledLink onClick={() => setCurrPage("History")}>
					The Recovered Archives
				</s.StyledLink>

				<s.StyledLink onClick={() => setCurrPage("MintPage")}>
					Reactivate a Gen 1 Mycelian
				</s.StyledLink>

				<s.StyledLink onClick={() => setCurrPage("FAQ")}>
					The Core $MELT Token
				</s.StyledLink>

				<s.StyledLink
					style={{ display: "none" }}
					onClick={() => setCurrPage("Gallery")}
				>
					The Order
				</s.StyledLink>

				<s.StyledLink
					style={{ display: "none" }}
					onClick={() => setCurrPage("royalDiamonds")}
				>
					The Sacred $MELT Token
				</s.StyledLink>
			</s.Headerlinks>

			<s.HeaderDiv>
				<ConnectButton />
				{/* <s.WalletBox style={wbStyle}>
            {blockchain.account !== "" ? (
            <>
            <s.TextSubTitle style={{fontSize: "clamp(1.2rem, 2vw, 1.8rem)", color: "white"}}>
            <Badge color={DOT}/> {walletAddress}
              </s.TextSubTitle>
            </>
            ) : null }
          </s.WalletBox> */}
			</s.HeaderDiv>
		</s.ResponsiveWrapperHeader>
	);
};
