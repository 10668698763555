export const ETHMainnet = {
	chainId: 1,
	chainName: "ETH",
	isTestChain: false,
	isLocalChain: false,
	multicallAddress: "0x5ba1e12693dc8f9c48aad8770482f4739beed696",
	getExplorerAddressLink: (address) =>
		`https://etherscan.io/address/${address}`,
	getExplorerTransactionLink: (transactionHash) =>
		`https://etherscan.io/tx/${transactionHash}`,
	rpcUrl: "https://ethereum.publicnode.com",
	blockExplorerUrl: "https://etherscan.io",
	nativeCurrency: {
		name: "ETH",
		symbol: "ETH",
		decimals: 18,
	},
};

export const Arbi = {
	chainId: 42161,
	chainName: "ETH",
	isTestChain: false,
	isLocalChain: false,
	multicallAddress: "0x80C7DD17B01855a6D2347444a0FCC36136a314de",
	getExplorerAddressLink: (address) =>
		`https://arbiscan.io/address/${address}`,
	getExplorerTransactionLink: (transactionHash) =>
		`https://arbiscan.io/tx/${transactionHash}`,
	rpcUrl: "https://1rpc.io/arb",
	blockExplorerUrl: "https://arbiscan.io",
	nativeCurrency: {
		name: "ETH",
		symbol: "ETH",
		decimals: 18,
	},
};

export const Pulse = {
	chainId: 369,
	chainName: "Pulse",
	isTestChain: false,
	isLocalChain: false,
	multicallAddress: "0x2f42a1e4ce7A590F94A75F38eBaFFa45E9a68ee1",
	getExplorerAddressLink: (address) =>
		`https://otter.pulsechain.com/address/${address}`,
	getExplorerTransactionLink: (transactionHash) =>
		`https://otter.pulsechain.com/tx/${transactionHash}`,
	// rpcUrl: "https://pulsechain.publicnode.com",
	rpcUrl: "https://rpc.pulsechain.com",
	blockExplorerUrl: "https://otter.pulsechain.com",
	nativeCurrency: {
		name: "PLS",
		symbol: "PLS",
		decimals: 18,
	},
};
