import { useEffect, useState } from "react";
import { useAppContext } from "../context/AppContext";
import * as s from "../styles/globalStyles";

export const TokenView = () => {
	const { fileExt } = useAppContext();

	const metadata = [
		{
			title: "In the beginning, there was ambition",
			text: `In a future where humans coexist with advanced cybernetic beings known as "Synths," a brilliant scientist's groundbreaking experiment integrates mycelium, an organic fungal network, with cybernetic technology. 
            `,
		},
		{
			title: "Ambition led to hope",
			text: "The result: MycoSynths, female cybernetic robots infused with living mycelium, possessing astonishing abilities to communicate with plants and harness nature's power.",
		},
		{
			title: "Hope blinded us",
			text: "Initially celebrated as eco-saviors, the MycoSynths became unwitting pawns in a sinister agenda when the mycelium's mysterious intelligence gradually took control.",
		},
		{
			title: "And in our blindness, evil prospered",
			text: "Now under its dominion, these once-hopeful creations secretly plot the takeover of planet Earth, the battle for the future of our planet ins in your hands.",
		},
		{
			title: "This mission is now yours, commander.",
			text: "Luckily, MycoSynths (mycelians) were all designed with remote a reactivation switch that allows you to reassert control. Your goal, commander, is to reactivate as many as you can to have the best chance to hold back the scourge.",
		},
		{
			title: "Acquire MELT Tokens",
			text: "Every reactivated unit is powered by $MELT (Mycelium Ecosystem Liquidity Token), a form of currency that allows the resistance to coordinate planetary actions against the scourge. One must first acquire 1m tokens to become a Mycelian Commander.",
		},
		{
			title: "Reactivate your mycelian squad",
			text: "Every reactivation uncovers the rank-category each Mycelian belongs to. The higher the rank the greater the access it grants you to launches of very liquid tokens forged by the Mycelians.",
		},
		{
			title: "Participate in the ecosystem and defeat the scourge",
			text: "The access you're granted is unique and is shared only with other commanders. Commanders will always step onto the battlefield first with their mycelian squads before clearing it for our remaining forces.",
		},
		{
			title: "We are brave, united and we will win.",
			text: "Our world was one beautiful and with your help we will make so once again. Onwards to battle, commander!",
		},
	];
	const numImages = Object.keys(metadata).length;
	const [currChar, setCurrChar] = useState(1);
	const currImg = `/config/images/story${currChar}.${fileExt}`;
	// console.log(currImg);
	const currTitle = metadata[currChar - 1]?.title || "";
	const currText = metadata[currChar - 1]?.text || "";

	const selectionFn = (x: number) => Math.min(numImages, (x % numImages) + 1);
	// const randomFn = (x) => 1 + parseInt(Math.random() * numImages);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setCurrChar((x) => selectionFn(x));
		}, 12500);
		return () => clearInterval(intervalId);
	}, []);

	return (
		<s.ResponsiveWrapper
			flex={1}
			style={{
				// padding: 24,
				// flexDirection: `row${(currChar % 2 && "-reverse") || ""}`,
				justifyContent: "space-around",
				...s.opaqueBgStyles,
				background: "none",
				border: "none",
				// flexDirection: "row-reverse",
			}}
		>
			{Array.from(Array(numImages).keys())
				.map((x) => x + 1)
				.map((x: number, i: number) => {
					const tempImg = `/config/images/story${i + 1}.${fileExt}`;
					const styleObj = (tempImg === currImg && {
						opacity: "0.8",
					}) || { display: "none" };
					return (
						<s.StyledImgMain
							key={tempImg}
							src={tempImg}
							type="image/jpg"
							style={styleObj}
						/>
					);
				})}
			{Array.from(Array(numImages).keys())
				.map((x) => x + 1)
				.map((x: number, i: number) => {
					const title = metadata[x - 1]?.title || "";
					const text = metadata[x - 1]?.text || "";
					const styleObj = (title === currTitle && {
						opacity: "1",
					}) || { display: "none" };
					return (
						<s.Container
							key={i}
							style={{ ...styleObj, ...s.opaqueBgStyles }}
						>
							<s.SpacerMedium />
							<s.TextTitle
								style={{
									textAlign: "center",
									minWidth: "30vw",
								}}
							>
								{title}
							</s.TextTitle>

							{/* <s.SpacerLarge /> */}
							<s.TextB>
								{text}
								<br></br>
							</s.TextB>
						</s.Container>
					);
				})}
		</s.ResponsiveWrapper>
	);
};
