// import * as React from 'react'
import React, {
	useEffect,
	useState,
	useRef,
	createContext,
	useContext,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import Web3 from "web3";
import { Notification, toaster } from "rsuite";
import { Loader } from "rsuite";
import { formatEther } from "@ethersproject/units";
import {
	useCall,
	useCalls,
	ERC20Interface,
	useContractFunction,
	useEthers,
	useConfig,
} from "@usedapp/core";
import { Contract, utils } from "ethers";
import tokenABI from "../ABIs/tokenABI.json";
import NFT from "../ABIs/NFT.json";
import GEN from "../ABIs/GEN.json";
import Config from "../ABIs/Config.json";

export const AppContext = React.createContext();

export const AppProvider = ({ children }) => {
	const dispatch = useDispatch();
	const blockchain = useSelector((state) => state.blockchain);
	const data = useSelector((state) => state.data);
	const [walletAddress, setAddress] = useState("Not Connected");
	const [claimingNft, setClaimingNft] = useState(false);
	const [feedback, setFeedback] = useState(``);
	const [tokens, setTokens] = useState(1);
	const [brd, setbrd] = useState("2px solid #FFFFFF");
	const [bxsh, setbxsh] = useState("0px 0px 3px 0px #FFFFFF");
	const [DOT, setDOT] = useState("red");
	const [type, setType] = React.useState("info");
	const [placement, setPlacement] = React.useState("topStart");
	const [currPage, setCurrPage] = React.useState("Story");
	const [authPass, setAuthPass] = useState(
		"0xe2b44128e989070cf6f2340fe4507fb131681bbdfc2867d9b3f88ab6a27a08bb"
	);
	const [pw, setPw] = useState("");
	const [authed, setAuthed] = useState(false);

	const errmessage = (
		<Notification
			style={{ background: "black", color: "white" }}
			type={"error"}
			header={"error"}
			closable
		>
			Sorry, something went wrong please try again later.
		</Notification>
	);
	const txmessage = (
		<Notification
			style={{ background: "black", color: "white" }}
			type={"success"}
			header={"success"}
			closable
		>
			Congrats, Mint Was successful.
		</Notification>
	);
	const mntmessage = (
		<Notification
			style={{ background: "black", color: "white" }}
			type={"info"}
			header={"stand by"}
			closable
		>
			<Loader /> Minting in Progress....
		</Notification>
	);

	const [CONFIG, setConfig] = useState({
		CONTRACT_ADDRESS: "",
		SCAN_LINK: "",
		NETWORK: {
			NAME: "",
			SYMBOL: "",
			ID: 0,
		},
		NFT_NAME: "",
		SYMBOL: "",
		MAX_SUPPLY: 1,
		DISPLAY_COST: 0,
		WL_Display: 0,
		GAS_LIMIT: 0,
		MAX_PER_TX: 0,
		MARKETPLACE: "",
		MARKETPLACE_LINK: "",
		Telegram: "",
		Discord: "",
		Twitter: "",
		SHOW_BACKGROUND: false,
	});

	const getCost = (step) => {
		return CONFIG.DISPLAY_COST * step;
		// let start = Number(data.totalSupply) ?? 0;
		// return getRealPrice(start, step) / 10000;
	};

	const claimNFTs = () => {
		// let cost = CONFIG.DISPLAY_COST * tokens;
		let cost = getCost(tokens);
		let price = Web3.utils.toWei(cost.toString(), "ether");
		let gasLimit = CONFIG.GAS_LIMIT;
		let totalGasLimit = String(gasLimit);
		console.log("Cost: ", price);
		console.log("Gas limit: ", totalGasLimit);
		console.log(`Dispaying using args: ${blockchain.account}, ${tokens}`);
		setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
		setClaimingNft(true);
		setbrd("2px solid yellow");
		setbxsh("0px 0px 3px 0px yellow");
		toaster.push(mntmessage, { placement });
		blockchain.smartContract.methods
			.mint(blockchain.account, tokens)
			.send({
				//gasLimit: String(totalGasLimit),
				//gasPrice: String(Web3.utils.toWei('20', 'gwei')),
				to: CONFIG.CONTRACT_ADDRESS,
				from: blockchain.account,
				value: price,
			})
			.once("error", (err) => {
				console.log(err);
				setFeedback(
					"Sorry, something went wrong please try again later."
				);
				setClaimingNft(false);
				toaster.push(errmessage, { placement });
				setbrd("2px solid red");
				setbxsh("0px 0px 3px 0px red");
			})
			.then((receipt) => {
				console.log(receipt);
				setFeedback(
					`Congratulations, you have just minted your very own ${CONFIG.NFT_NAME} NFT!`
				);
				toaster.push(txmessage, { placement });
				setbrd("2px solid green");
				setbxsh("0px 0px 3px 0px green");
				setClaimingNft(false);
				dispatch(fetchData(blockchain.account));
			});
	};

	const decrementtokens = () => {
		let newtokens = tokens - 1;
		if (newtokens < 1) {
			newtokens = 1;
		}
		setTokens(newtokens);
	};

	const incrementtokens = () => {
		let newtokens = tokens + 1;
		let maxTokens = Math.min(
			CONFIG.MAX_SUPPLY - data.totalSupply,
			CONFIG.MAX_PER_TX
		);
		if (newtokens > maxTokens) {
			newtokens = maxTokens;
		}
		setTokens(newtokens);
	};

	const getData = () => {
		if (blockchain.account !== "" && blockchain.smartContract !== null) {
			dispatch(fetchData(blockchain.account));
			setAddress(
				blockchain.account.substring(0, 4) +
					"..." +
					blockchain.account.substring(38, 42)
			);
			setDOT("green");
		}
	};

	const getConfig = async ({ setConfig }) => {
		const configResponse = await fetch("/config/config.json", {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		});
		const rawResponse = await configResponse;
		const config = await rawResponse.json();
		setConfig(config);
	};

	const useContractConfig = ({ refresh = "everyBlock" }) => {
		const configObj = {};
		const ConfigContractAddress = contractsList.Config;
		const calls = [
			{
				contractAddress: ConfigContractAddress,
				method: "getConfig",
				args: [],
			},
		];
		const values =
			ConfigContractAddress &&
			useContractMethods(calls, { keepError: true, dec: 0, refresh });
		if (typeof values === "object" && values?.getConfig) {
			const { getConfig } = values;

			if (getConfig?.length && getConfig?.length > 0) {
				for (let index = 0; index < getConfig.length; index += 2) {
					configObj[getConfig[index]] = getConfig[index + 1];
				}
			}
		}
		return configObj;
	};

	// const setContractConfig = () => {
	// 	const configObj = getContractConfig();
	// 	if (
	// 		!!configObj &&
	// 		typeof configObj === "object" &&
	// 		Object.keys(configObj)?.length > 0
	// 	) {
	// 		setContractsList(...contractsList, ...configObj);
	// 		console.log("configObj: ", configObj);
	// 	}
	// };

	useEffect(() => {
		getConfig({ setConfig });
	}, []);

	useEffect(() => {
		getData();
	}, [blockchain.account]);

	const {
		account,
		chainId,
		switchNetwork,
		deactivate,
		activateBrowserWallet,
	} = useEthers();
	const configObj = useConfig();

	const connectionStatus = () => {
		const walletChainIDHex = window?.ethereum?.chainId || chainId;
		const walletChainID =
			(walletChainIDHex &&
				utils.formatUnits(BigInt(walletChainIDHex).toString(), 0)) ||
			chainId;
		return !account
			? -1
			: walletChainID && !configObj.readOnlyUrls[walletChainID]
			? 0
			: 1;
	};

	const handleConnection = () => {
		const conStatus = connectionStatus();
		conStatus == -1 && activateBrowserWallet();
		conStatus == 0 && switchNetwork(CONFIG.NETWORK.ID || 1);
		conStatus == 1 && deactivate();
	};

	const [contractsList, setContractsList] = useState({
		Config: "0xb61E4E1B89cd9ec6282337e702929480b2E8f569",
		NFT: "0xB8fDD35d33075D0C95D45fF004aD7f625d1787C5",
		GEN: "0x3aD026833277eA79270fD8FA0Be397100118EE5b",
	});
	// const contractsList = {s
	//   IkNFT: '0xBdE977F5Deca3325062D24766B25059AF05E2F1e',
	//   IkGEN: '0xb6A781b16bAD4E15725Db25157e11cD82A251760',
	// }

	const contractABIs = {
		"0x6982508145454ce325ddbe47a25d4ec3d2311933": new utils.Interface(
			tokenABI
		),
		"0xB8fDD35d33075D0C95D45fF004aD7f625d1787C5": new utils.Interface(NFT),
		"0x3aD026833277eA79270fD8FA0Be397100118EE5b": new utils.Interface(GEN),
		"0xb61E4E1B89cd9ec6282337e702929480b2E8f569": new utils.Interface(
			Config
		),
		NFT: new utils.Interface(NFT),
		GEN: new utils.Interface(GEN),
		Config: new utils.Interface(Config),
	};

	const getContractABI = (contractAddress) => {
		const contractName = Object.keys(contractsList).find(
			(x) => contractsList[x] === contractAddress
		);
		return contractName && contractABIs[contractName];
	};

	const getABIByAddress = (tokenAddress) => {
		return (
			(typeof tokenAddress === "string" &&
				Object.entries(contractABIs).find(([x, y]) =>
					x.toLowerCase().includes(tokenAddress.toLowerCase())
				)?.[1]) ||
			Object.values(contractABIs)?.[0]
		);
	};

	const getFormattedETH = (x) => {
		if (typeof x === "object") {
			return (Object.keys(x).includes("_hex") && formatEther(x)) || 0;
		}
		return 0;
	};

	const getFormattedETHs = (...args) => {
		return args.map((x) => (!!x && getFormattedETH(x)) || 0);
	};

	const flattenItem = (val, dec) => {
		if (typeof val === "object" && Object.keys(val)?.[0] === "_hex") {
			return Number(utils.formatUnits(BigInt(val._hex).toString(), dec));
		}
		if (typeof val === "string" || typeof val === "boolean") {
			return val;
		}
		if (typeof val === "object" && typeof val?.length === "number") {
			return [
				...val.map((x) => {
					if (
						typeof x === "object" &&
						Object.keys(x)?.[0] === "_hex"
					) {
						return Number(
							utils.formatUnits(BigInt(x._hex).toString(), dec)
						);
					}
					if (typeof x === "string") {
						return x;
					}
				}),
			];
		}
	};

	const flattenResults = (results, keepError = true, decimals = 18) => {
		const resultsFlattened = {};
		const resKeys = Object.keys(results);
		Object.values(results).forEach((x, i) => {
			if (typeof x === "object" && typeof x?.length === "number") {
				resultsFlattened[resKeys[i]] = flattenItem(x[0], decimals);
			} else {
				if (keepError) resultsFlattened[resKeys[i]] = x;
			}
		});
		return resultsFlattened;
	};

	const useContractMethod = (contractAddress, method, args) => {
		const abi = getABIByAddress(contractAddress);
		if (![contractAddress, abi, method].every((x) => !!x)) return 0;

		const { error, value } = useCall({
			contract: new Contract(contractAddress, abi),
			method,
			args,
		}) ?? { error: null, value: null };
		return (value && value?.[0]) || 0;
	};

	const useContractMethods = (
		callsArr,
		{ keepError, dec, refresh = "everyBlock" }
	) => {
		const callsArg = callsArr.map((call, idx) => {
			const { contractAddress, method, args } = call;
			const abi = getABIByAddress(contractAddress);
			if (![contractAddress, abi, method].every((x) => !!x)) {
				return { method: method || `NO_METHOD${idx}` };
			}
			return {
				contract: new Contract(contractAddress, abi),
				method,
				args,
			};
		});

		const callsFinal = callsArg.filter(
			(x) => typeof x.args?.length === "number"
		);
		// console.log(callsFinal);
		const values = {};
		const results = useCalls(callsFinal, { refresh });
		// console.log(results);
		results &&
			results.forEach((result, idx) => {
				if (result && !result.error)
					values[callsFinal[idx].method] = result.value;
			});
		callsArg.forEach((call, idx) => {
			if (
				call &&
				typeof call.args?.length === "number" &&
				values[call.method] !== undefined
			)
				return;
			// values[call.method] = `call to method ${call.method} failed.`;
			values[call.method] = null;
		});
		return flattenResults(values, keepError, dec);
	};

	const useContractMutation = (contractAddress, method) => {
		const contract = new Contract(
			contractAddress,
			getABIByAddress(contractAddress)
		);
		const { state, send } = useContractFunction(contract, method, {
			transactionName: method,
		});

		return { state, send };
	};

	const bgImages = {
		MintPage: "bg6.jpg",
		Story: "bg1.jpg",
		Gallery: "bg4.jpg",
		FAQ: "bg8.jpg",
		History: "bg7.jpg",
		royalDiamonds: "bg5.jpg",
	};

	const CacheBgsNew = () => {
		Object.values(bgImages).forEach(
			(x) => (new Image().src = `config/images/${x}`)
		);
	};

	const CacheBgs = () => {
		return (
			<>
				{Object.values(bgImages).map((x) => {
					const currSrc = `config/images/${x}`;

					return (
						<img
							key={currSrc}
							src={`config/images/${x}`}
							style={{ display: "inline", maxWidth: "0px" }}
						/>
					);
				})}
			</>
		);
	};

	const fileExt = "jpg";
	const metadata = {
		1: {
			rarity: "Mythical (1%)",
			caste: "Wraiths",
			abilities: "Control Of The Elements",
			rewardMultiplier: "20x",
		},
		2: {
			rarity: "Legendary (2%)",
			caste: "Lightning Weavers",
			abilities: "Lightning Storms",
			rewardMultiplier: "10x",
		},
		3: {
			rarity: "Rare (5%)",
			caste: "Fire Demons",
			abilities: "Ancient Fire Dragons",
			rewardMultiplier: "5x",
		},
		4: {
			rarity: "Uncommon (12%)",
			caste: "Water Beings",
			abilities: "Engulfing Tsunamis",
			rewardMultiplier: "2x",
		},
		5: {
			rarity: "Common (80%)",
			caste: "Dark Witches",
			abilities: "Dark Spell Casting",
			rewardMultiplier: "1x",
		},
	};

	const footerIconSize = 36;

	const value = {
		config: CONFIG,
		blockchain,
		data,
		connect,
		dispatch,
		bgImages,
		footerIconSize,
		fileExt,
		metadata,
		CacheBgs,
		CacheBgsNew,
		useContractMethod,
		useContractMethods,
		contractABIs,
		contractsList,
		setContractsList,
		useContractMutation,
		getFormattedETH,
		getFormattedETHs,
		account,
		chainId,
		switchNetwork,
		deactivate,
		activateBrowserWallet,
		configObj,
		handleConnection,
		connectionStatus,
		walletAddress,
		setAddress,
		authPass,
		pw,
		setPw,
		authed,
		setAuthed,
		useContractConfig,
		claimingNft,
		setClaimingNft,
		feedback,
		setFeedback,
		tokens,
		setTokens,
		brd,
		setbrd,
		bxsh,
		setbxsh,
		DOT,
		setDOT,
		type,
		setType,
		placement,
		setPlacement,
		currPage,
		setCurrPage,
		errmessage,
		txmessage,
		mntmessage,
		getCost,
		claimNFTs,
		decrementtokens,
		incrementtokens,
		getData,
	};

	return <AppContext.Provider {...{ value }}>{children}</AppContext.Provider>;
};

// export const useConfig = () => {

// 	const context = useContext(AppContext);
// 	console.log(context)
// 	const {config} = context;
// 	return config;

// }

export const useAppContext = () => {
	return useContext(AppContext);
};
