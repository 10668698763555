import { createGlobalStyle } from "styled-components";
import RenoMono from "./fonts/RenoMono.otf";
import RoadRage from "./fonts/RoadRage.otf";
import Momo from "./fonts/Momo.otf";
import Upheaval from "./fonts/upheavtt.ttf";
import Roboto from "./fonts/Roboto-Regular.ttf";
import RobotoLight from "./fonts/Roboto-Light.ttf";
import ROG from "./fonts/ROGFontsv1.6-Regular.ttf";

// import Gumball from './Gumball.ttf';
import Puna from "./fonts/Puna.otf";
import Sphere from "./fonts/Sphere.otf";
const GlobalStyle = createGlobalStyle`

    @font-face {
        font-family: 'ROG';
        src: url(${ROG}) format('truetype');
    }

    @font-face {
        font-family: 'Roboto';
        src: url(${Roboto}) format('truetype');
    }

    @font-face {
        font-family: 'RobotoLight';
        src: url(${RobotoLight}) format('truetype');
    }

    @font-face {
        font-family: 'Upheaval';
        src: url(${Upheaval}) format('truetype');
    }

    @font-face {
        font-family: 'Renomono';
        src: url(${RenoMono}) format('opentype');
    }

    @font-face {
        font-family: 'RoadRage';
        size-adjust: 40%;
        // font-weight: Bold;
        src: url(${Sphere}) format('opentype');
    }

`;

// export const Renomono = createGlobalStyle`
//     @font-face {
//         font-family: 'Renomono';
//         src: url(${RenoMono}) format('opentype');
//     }
// `;

export default GlobalStyle;
