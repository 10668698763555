import { useEffect, useState } from 'react';
import {useAppContext} from '../context/AppContext';
import * as s from "../styles/globalStyles";

export const CarouselSection = () => {
    
  const {fileExt, metadata} = useAppContext();

  const [currChar, setCurrChar] = useState(1);
  const currMetadata = metadata[currChar];
  const currImg = `/config/images/${currChar}.${fileExt}`

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrChar(x => x % 25 + 1);
    }, 2000);
    return () => clearInterval(intervalId); 
  }, [])


  return (
			<s.SecContainer id="sneak">
            <s.TextTitle style={s.opaqueBgStyles}>
              Choose your caste. <br/>
              Become a force to be reckoned with.
            </s.TextTitle>

           <s.ResponsiveWrapper flex={1} style={{ padding: '2rem' , maxHeight: '50vmax' }} test>
              {/*<s.SpacerLarge/>*/}
              {Object.keys(metadata).map((x,i) => {
                const tempImg = `/config/images/${i+1}.${fileExt}`
                const styleObj = tempImg === currImg && {} || {display: 'none'}
                return (<s.StyledImgMain key={tempImg} src={tempImg} type="image/jpg" style={styleObj}/>)
              })}
              {/*<s.StyledImgMain key={currImg} src={currImg} type="image/jpg"/>*/}
              <s.ResponsiveWrapperTraits>
                <s.ResponsiveWrapperTrait>
                  <s.TextO>Caste</s.TextO>
                  <s.TextO key={currMetadata.caste}>{currMetadata.caste}</s.TextO>
                </s.ResponsiveWrapperTrait>
                <s.ResponsiveWrapperTrait>
                  <s.TextO>Rarity</s.TextO>
                  <s.TextO key={currMetadata.rarity}>{currMetadata.rarity}</s.TextO>                  
                </s.ResponsiveWrapperTrait>
                <s.ResponsiveWrapperTrait>
                  <s.TextO>Main ability</s.TextO>
                  <s.TextO key={currMetadata.abilities}>{currMetadata.abilities}</s.TextO>                  
                </s.ResponsiveWrapperTrait>
                <s.ResponsiveWrapperTrait>
                  <s.TextO>Diamond Claim Multiplier</s.TextO>
                  <s.TextO key={currMetadata.rewardMultiplier}>{currMetadata.rewardMultiplier}</s.TextO>                  
                </s.ResponsiveWrapperTrait>
              </s.ResponsiveWrapperTraits>
            </s.ResponsiveWrapper>
      </s.SecContainer>
)
  }
