import { useEffect, useState } from "react";
import { useAppContext } from "../context/AppContext";
import * as s from "../styles/globalStyles";
import { MintDataView } from "./MintDataView";

export const MintArea = () => {
	const { fileExt, metadata } = useAppContext();
	const numImages = 99;
	const [currChar, setCurrChar] = useState(1);
	const currImg = `/config/images/${currChar}.${fileExt}`;
	const selectionFn = (x) => Math.min(numImages, (x % numImages) + 1);
	const randomFn = (x) => 1 + parseInt(Math.random() * numImages);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setCurrChar((x) => randomFn(x));
		}, 2000);
		return () => clearInterval(intervalId);
	}, []);

	return (
		<s.ResponsiveWrapper
			flex={1}
			style={{ padding: 24, ...s.opaqueBgStyles }}
		>
			{[...Array(numImages).keys()]
				.map((x) => x + 1)
				.map((x, i) => {
					const tempImg = `/config/images/${i + 1}.${fileExt}`;
					const styleObj = (tempImg === currImg && {
						opacity: "1",
					}) || { display: "none" };
					return (
						<s.StyledImgMain
							key={tempImg}
							src={tempImg}
							type="image/jpg"
							style={styleObj}
						/>
					);
				})}
			{/* <s.StyledImgMain controls src="/config/images/force.jpg"/> */}
			{/* <s.SpacerSmall/> */}
			<MintDataView />
			{/* <s.SpacerLarge /> */}
		</s.ResponsiveWrapper>
	);
};

/*
alright so mint is dynamic with a hard cap of 10,000 units. total MELT supply is 1m tokens, 500k for mint airdrop 500k for LP.
if only 500 get minted, you get 1k tokens per mint. mint costs 750k pls, discounted to 500k if you have the password. say everyone has the pw and we mint 2.5k pieces, that's $45k. 25% goes into the LP, 25% is used to buy atropa and airdrop to minters. we keep 22.5k. starting mcap is then $22.5k, and everyone gets 200 tokens per mint, which is $9 so they start at 50% ROI pre launch
*/
