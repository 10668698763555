import { useConfig } from "@usedapp/core";
import { useAppContext } from "../context/AppContext";
import * as s from "../styles/globalStyles";
import { Panel, PanelGroup } from "rsuite";

export const FAQSection = () => {
	const web3Config = useConfig();
	const nativeCurrencySymbol =
		web3Config.networks[0].nativeCurrency.symbol || "ETH";

	const { setCurrPage, useContractMethods, contractsList, getFormattedETHs } =
		useAppContext();

	const { NFT: NFTContractAddress, GEN: GenContractAddress } = contractsList;

	const calls = [
		{ contractAddress: NFTContractAddress, method: "maxSupply", args: [] },
		{ contractAddress: NFTContractAddress, method: "cost", args: [] },
		{ contractAddress: NFTContractAddress, method: "WLcost", args: [] },
	];

	const values =
		NFTContractAddress &&
		GenContractAddress &&
		useContractMethods(calls, { keepError: true, dec: 0, refresh: 20 });

	const {
		maxSupply = 1000,
		cost: rawCost = 0,
		WLcost: rawWLcost = 0,
	} = values;
	const cost = rawCost / 10 ** 18 || 0;
	const WLCost = rawWLcost / 10 ** 18 || 0;
	// const { caste, mult } = { caste: "", mult: "" };
	return (
		<s.SecContainer id="faq">
			<s.TextTitle style={s.opaqueBgStyles}>
				At the center of it all lies the MELT token
				<br /> powered by the ATROPA ecosystem
			</s.TextTitle>
			<s.SpacerLarge />
			{/* <TokenView /> */}

			{true && (
				<PanelGroup
					style={{
						width: "80%",
						borderColor: "#e10f96",
						color: "steelblue",
						marginBottom: "30px",
					}}
					accordion
					bordered
				>
					<Panel
						style={{
							fontFamily: "RoadRage",
							color: "#0059E50",
							fontSize: "1rem",
						}}
						header="What are the Mycelians?"
						defaultExpanded
					>
						<s.TextP style={{ textAlign: "left" }}>
							The Mycelians is a post apocalyptic story driven
							ecosystem that revolves around the Mycelians NFT
							collection, which represents powerful synthetic
							female robots known as the Mycelians.
							<br />
							<br />
							As the Mycelians have been taken over by the
							Mycelium scourge, each commander must "reactivate"
							them, a process by which they are reprogrammed back
							under your command.
							<br />
							<br />
							The bigger your squad of mycelians the better your
							odds of success as the story evolves.
						</s.TextP>
					</Panel>
					<Panel
						style={{ fontFamily: "RoadRage", color: "#050F3880" }}
						header="What are the different rarity tiers of the Mycelians?"
					>
						<s.TextP style={{ textAlign: "left" }}>
							Each generation of Mycelians is divided into 10
							different categories that follow different rarity
							tiers. The simple drones are the most common ones,
							while the hive chieftains are legendary and very
							hard to find.
							<br />
							<br />
							The rarer a reactivated Mycelian is, the more
							benefits her commander is awarded.
							<br />
							<br />
							You can view the different categories on the
							<s.StyledLink
								onClick={() => setCurrPage("Gallery")}
								style={{ margin: "0 0.2rem" }}
							>
								"The Order"
							</s.StyledLink>{" "}
							page.
						</s.TextP>
					</Panel>
					<Panel
						style={{ fontFamily: "RoadRage", color: "#050F3880" }}
						header="What are the benefits of reactivating a Mycelian?"
					>
						<s.TextP style={{ textAlign: "left" }}>
							Among their many qualities, the Mycelians are very
							well known for being excellent engineers, and can
							work together in large hive-like teams to generate
							new advanced tokens.
							<br />
							<br />
							Once all mycelians of a certain generation have been
							reactivated (minted), a portion of the total
							reactivation cost will be used to fuel the growth
							engine (liquidity pool) of a unique token they will
							create. and any commander who has reactivated a
							mycelian will be allowed to participate in the
							launch process.
							<br />
							<br />
							In doing so the Mycelians are creating very liquid
							tokens and granting exclusive access to their
							commanders before any external forces can
							participate post launch. This is why a Mycelian
							commander is one of the most desirable titles known
							to humanity.
							<br />
							<br />
							Every Mycelian a commander reactivates increases his
							max wallet restriction for their upcoming token
							launch by a certain amount. If for example one mints
							a legendary hive-chieftain Mycelian, their max
							wallet restriction is automatically raised by a
							whole 1%. These raises stack up so 2 legendary
							Mycelian will raise the restriction by 2%.
						</s.TextP>
					</Panel>
					<Panel
						style={{ fontFamily: "RoadRage", color: "#050F3880" }}
						header="How can I reactivate a Mycelian?"
					>
						<s.TextP style={{ textAlign: "left" }}>
							To reactivate a Mycelian simply go to the{" "}
							<s.StyledLink
								onClick={() => setCurrPage("Gallery")}
								style={{ margin: "0 0.2rem" }}
							>
								"Reactivate a Gen 1 Mycelian"
							</s.StyledLink>{" "}
							<br />
							<br />
							page, connect your MetaMask wallet, choose the
							number of Mycelians you would like to reactivate and
							click mint. While anyone can reactivate Gen 1
							Mycelians, Only commanders may be awarded the full
							benefits of commanding a Gen 1 Mycelian. To become a
							commander, one must hold at least 1m MELT tokens,
							which can be purchased on Pulsex.com
						</s.TextP>
					</Panel>
					<Panel
						style={{ fontFamily: "RoadRage", color: "#050F3880" }}
						header="How much does it cost to reactivate a Mycelian?"
					>
						<s.TextP style={{ textAlign: "left" }}>
							A Gen 1 Mycelian costs {cost} {nativeCurrencySymbol}{" "}
							each to mint. If the commander has the special MELT
							Cypher, they can enter it on the reactivation page
							for a reduced reactivation cost of {WLCost}{" "}
							{nativeCurrencySymbol}.
						</s.TextP>
					</Panel>
					<Panel
						style={{ fontFamily: "RoadRage", color: "#050F3880" }}
						header="Can I choose which category I want to mint?"
					>
						<s.TextP style={{ textAlign: "left" }}>
							The damage the mycelium caused to our world was
							extensive, and we've lost the ability to track our
							Mycelians that are out there in the dark. As a
							result commanders have no choice but to reactivate
							them blindly, which means that you can't know what
							category a Mycelian will belong to until they're
							back home safely. Good luck to you commander!
						</s.TextP>
					</Panel>
					<Panel
						style={{ fontFamily: "RoadRage", color: "#050F3880" }}
						header="How many Mycelians can one reactivate?"
					>
						<s.TextP style={{ textAlign: "left" }}>
							A commander can mint as many as they wish, the more
							they mint the greater their odds of defeating the
							scourge. Only {maxSupply} Gen 1 Mycelians were ever
							made and unfortunately we cannot hope to reactivate
							any more.
						</s.TextP>
					</Panel>
					{/*              <Panel style={{color: "#e10f96"}} header="question any legal restrictions apply to the mint?">
              <s.TextP style={{textAlign: "left"}}>
                Yes, you are not allowed to participate in the mint in any way if you are a resident or 
                citizen of the US or any of OFAC's sanctioned jurisdictions (read more: https://home.treasury.gov/policy-issues/financial-sanctions/sanctions-programs-and-country-information), 
                or if your participation is in violation of applicable laws in your jurisdiction.
              </s.TextP>
              </Panel>
*/}{" "}
				</PanelGroup>
			)}
		</s.SecContainer>
	);
};
