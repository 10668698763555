import * as s from "../styles/globalStyles";
import React from 'react'

export const Diamonds = () => {

  return (
      <s.SecContainer id="diamonds">
        <s.TextTitle style={s.opaqueBgStyles}>
          The Goddess returns. <br/>
          Claim your spoils of war. <br/>
          Coming soon.
        </s.TextTitle>
        <s.StyledImgSplash key={'/config/images/force2.jpg'} src={'/config/images/force2.jpg'} type="image/jpg" opacityOverride={0.5}
        style={{maxWidth: "clamp(300px, 50vw, 600px"}}/>
      </s.SecContainer>
    )
}