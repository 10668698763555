import * as s from "../styles/globalStyles";
import { useAppContext } from "../context/AppContext";
import {
	HeaderContainer,
	MintArea,
	TitleContainer,
	StorySection,
	CarouselSection,
	FAQSection,
	FooterSection,
	Diamonds,
	HistorySection,
} from "./index.js";

export const AuthedScreen = () => {
	const { currPage } = useAppContext();

	return (
		<s.Container flex={1}>
			<HeaderContainer />
			{/* <CacheBgs /> */}
			{currPage === "MintPage" && (
				<s.SecContainer>
					<s.SpacerLarge />

					<TitleContainer />
					<s.SpacerSmall />

					<MintArea />
				</s.SecContainer>
			)}
			<s.SpacerLarge />
			{currPage === "Story" && (
				<s.BigContainer>
					<StorySection />
				</s.BigContainer>
			)}
			{currPage === "Gallery" && (
				<s.BigContainer>
					<CarouselSection />
				</s.BigContainer>
			)}
			{currPage === "History" && (
				<s.BigContainer>
					<HistorySection />
				</s.BigContainer>
			)}
			{currPage === "FAQ" && (
				<s.BigContainer>
					<FAQSection />
				</s.BigContainer>
			)}
			{currPage === "royalDiamonds" && (
				<s.BigContainer>
					<Diamonds />
				</s.BigContainer>
			)}
			<FooterSection />
			{/*<s.SpacerMedium />*/}
		</s.Container>
	);
};
