import react, { useEffect, useState } from "react";
import * as s from "../styles/globalStyles";
import { useAppContext } from "../context/AppContext";
import { utils } from "ethers";

export const UnauthedScreen = () => {
	const { authPass, pw, setPw, setAuthed } = useAppContext();
	let canPass =
		authPass?.length > 0 &&
		pw?.length > 0 &&
		authPass === utils.solidityKeccak256(["string"], [pw.toLowerCase()]);
	useEffect(() => {
		if (canPass) {
			setTimeout(() => {
				setAuthed(true);
			}, 7000);
		}
		return () => {};
	}, [pw]);

	const styleObj = (canPass && { color: "green" }) || {};
	const borderObj = { border: "3px red solid", animation: "" };
	const animatedInput = !canPass && (
		<s.InputOAnimated
			style={styleObj}
			aDelay={12}
			value={pw}
			onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
				setPw(e.target.value)
			}
			disabled={canPass}
		/>
	);
	const aBorder = { aBorder: (canPass && "#00FF00") || "#FF0000" };
	const unauthContent = (
		<s.UnauthContainer flex={1}>
			<s.TextTitle {...aBorder} style={{ ...s.opaqueBgStyles }}>
				<s.TextTitleAnimated>Intruder alert:</s.TextTitleAnimated>
				<br />
				<s.TextTitleAnimated aDelay={2}>
					Unauthorized access.
				</s.TextTitleAnimated>
				<br />
				<s.TextTitleAnimated aDelay={4}>
					Control center:
				</s.TextTitleAnimated>
				<br />
				<s.TextTitleAnimated aDelay={6}>
					Access restricted.
				</s.TextTitleAnimated>
				<br />
				<br />
				<br />
				<s.TextTitleAnimated aDelay={8}>
					Enter your MELT
				</s.TextTitleAnimated>
				<br />
				<s.TextTitleAnimated aDelay={10}>
					Cypher to procced:
				</s.TextTitleAnimated>
				{animatedInput}{" "}
			</s.TextTitle>
		</s.UnauthContainer>
	);

	const postAuthContent = (
		<s.UnauthContainer flex={1}>
			<s.TextTitle {...aBorder} style={s.opaqueBgStyles}>
				<s.TextTitleAnimated key={`${canPass}-1`} style={styleObj}>
					Cypher accepted.
				</s.TextTitleAnimated>
				<br />
				<s.TextTitleAnimated
					key={`${canPass}-2`}
					style={styleObj}
					aDelay={2}
				>
					Access granted.
				</s.TextTitleAnimated>
				<br />
				<s.TextTitleAnimated
					key={`${canPass}-3`}
					style={styleObj}
					aDelay={4}
				>
					Welcome Commander.
				</s.TextTitleAnimated>
				<br />
				{animatedInput}
			</s.TextTitle>
		</s.UnauthContainer>
	);

	return (canPass && postAuthContent) || unauthContent;
};
