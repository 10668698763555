import { useAppContext } from "../context/AppContext";
import * as s from "../styles/globalStyles";

export const StorySection = () => {
	const { config } = useAppContext();
	const tempImg = `/config/images/${"banner"}.${"png"}`;

	return (
		<s.SecContainer
			id="story"
			style={{
				// background: `url(${tempImg})`,
				// backgroundRepeat: "no-repeat",
				// backgroundSize: "cover",
				...s.opaqueBgStyles,
				// opacity: "1",
			}}
		>
			{/* <s.StyledImgMain
				key={tempImg}
				src={tempImg}
				type="image/jpg"
				style={{ width: "70%", opacity: 0.9 }}
			/> */}

			<s.SpacerMedium />
			<s.TextTitle>
				{" "}
				Welcome to the immersive world of the {config.NFT_NAME}
			</s.TextTitle>
			<s.SpacerLarge />
			<s.TextB>
				Step into the future where technology and nature converge in a
				tale of intrigue, innovation, and ecological awakening.
				<br></br>
			</s.TextB>
			<s.SpacerLarge />
			<s.TextB>
				In this captivating adventure, you'll explore the boundless
				possibilities of a world where cybernetic beings, known as
				Synths, walk among us, coexisting with humans. But beneath the
				surface of this utopia lies a silent threat—an ancient and
				intelligent force that seeks to seize control. The mycelium, an
				interconnected web of fungal threads, holds secrets that could
				reshape the destiny of our planet.
				<br></br>
			</s.TextB>
			<s.SpacerLarge />
		</s.SecContainer>
	);
};
