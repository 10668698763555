import { useAppContext } from "../context/AppContext";
import * as s from "../styles/globalStyles";
import { LiaTelegramPlane } from "react-icons/lia";
import { PiTwitterLogo } from "react-icons/pi";
import { TbBrandDiscord } from "react-icons/tb";
import { SiOpensea } from "react-icons/si";

export const FooterSection = () => {
	const {
		config,
		contractsList: { NFT: NFTContractAddress },
		footerIconSize,
	} = useAppContext();

	return (
		<s.SecContainer id="">
			<s.socialDiv>
				<a href={config.Telegram} target={"_blank"}>
					{/* <s.Icons src="/config/images/telegram.svg" alt="telegram" /> */}
					<s.iconDiv>
						<LiaTelegramPlane
							size={footerIconSize}
							color={"#FF0800FF"}
						/>
					</s.iconDiv>
				</a>
				<a href={config.Twitter} target={"_blank"}>
					<s.iconDiv>
						<PiTwitterLogo
							size={footerIconSize}
							color={"#FF0800FF"}
						/>
					</s.iconDiv>
				</a>
				<a href={config.Discord} target={"_blank"}>
					{/* <s.Icons src="/config/images/discord.svg" alt="discord" /> */}
					<s.iconDiv>
						<TbBrandDiscord
							size={footerIconSize}
							color={"#FF0800FF"}
						/>
					</s.iconDiv>
				</a>
				<a
					href={`${config.MARKETPLACE_LINK}/${NFTContractAddress}`}
					target={"_blank"}
				>
					{/* <s.Icons src="/config/images/opensea.svg" alt="opensea" /> */}
					<s.iconDiv>
						<SiOpensea size={footerIconSize} color={"#FF0800FF"} />
					</s.iconDiv>
				</a>
			</s.socialDiv>
			<s.SpacerLarge />
			<s.TextP style={{ backgroundColor: "#0000", padding: "0px" }}>
				Copyright © {new Date().getFullYear()} {config.NFT_NAME}
			</s.TextP>
		</s.SecContainer>
	);
};
