import { useAppContext } from "../context/AppContext";
import * as s from "../styles/globalStyles";

export const TitleContainer = () => {
	const { config } = useAppContext();

	return (
		<s.Container flex={1} jc={"center"} ai={"center"}>
			<s.TextTitle style={s.opaqueBgStyles}>
				Reactivate your {config.NFT_NAME} Squad.
				<br />
				Hold back the scourge. Fight for our future.
				<br />
				Unlock riches beyond your imagination.
				<br />
			</s.TextTitle>
		</s.Container>
	);
};
